<template>
    <v-card color="secondary">
        <v-row align="center" class="pr-10 pl-10">
            <v-col class="d-flex flex-items-center flex-column" cols="12" sm="6">
                <v-text-field v-model.number="params.tcam_first_line" dense label="First Line" outlined
                              @blur="updateConfig"></v-text-field>
                <v-text-field v-model.number="params.tcam_last_line" dense label="Last Line" outlined
                              @blur="updateConfig"></v-text-field>
                <v-text-field v-model.number="params.tcam_tripod_capture_lines" dense hide-details
                              label="Tripod Capture Lines"
                              outlined @blur="updateConfig"></v-text-field>
                <div class="d-flex mt-3">
                    <v-spacer></v-spacer>
                    <v-switch v-model="isOrtho" color="success" dense
                              hide-details label="Съемка ортофото"></v-switch>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col class="d-flex flex-items-center flex-column" cols="12" sm="6">
                <v-text-field v-model.number="params.tcam_Exposure" dense label="Exposure" outlined
                              @blur="updateConfig"></v-text-field>
                <v-text-field v-model.number="params.tcam_Gain" dense label="Gain" outlined
                              @blur="updateConfig"></v-text-field>
                <v-text-field v-model.number="params.lines" dense hide-details label="Lines Count"
                              outlined @blur="updateConfig"></v-text-field>
                <div class="d-flex mt-3">
                    <v-spacer></v-spacer>
                    <v-switch v-model="isSpecter" color="success" dense
                              hide-details label="Съемка спектра"></v-switch>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "FlightConfig",
    data() {
        return {
            params: {
                tcam_Exposure: 13000,
                tcam_Gain: 16,
                tcam_first_line: 0,
                tcam_last_line: 330,
                tcam_tripod_capture_lines: 1000,
                lines: 100
            },
            defaultValues: {
                tcam_Exposure: 13000,
                tcam_Gain: 16,
                tcam_first_line: 0,
                tcam_last_line: 330,
                tcam_tripod_capture_lines: 1000,
                lines: 100
            },
            isSpecter: false,
            isOrtho: false,
        }
    },
    mounted() {
        this.params = this.getCameraConfig
        console.log(this.params)
    },
    computed: {
        getCameraConfig() {
            return this.$store.getters.getCameraConfig
        },
    },
    watch: {
        getCameraConfig(val) {
            this.params = val
        },
    },
    methods: {
        updateConfig() {
            for (const [key, value] of Object.entries(this.params)) {
                if (value === "") {
                    this.params[key] = this.defaultValues[key]
                }
            }
            this.$store.dispatch('saveCameraConfig', this.params)
        },
    },
}
</script>

<style scoped>

</style>
