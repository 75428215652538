<template>
    <v-flex class="flex-column align-center">
        <v-card class="pb-1" color="secondary" width="100%">
            <v-card-title>Меню управления</v-card-title>
            <v-card-subtitle>
                <v-row>
                    <v-col cols="12" md="6">
                        <div>{{ time }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="d-flex flex-grow-1 align-items-center">
                            <span>Занятое место:</span>
                            <v-progress-linear :color="textColor()" :value="getUsagePercent" height="25">
                                <template v-slot="">
                                    <strong>{{ getMemoryStatus }}</strong>
                                </template>
                            </v-progress-linear>
                        </div>
                    </v-col>
                </v-row>
            </v-card-subtitle>
            <div>
                <v-flex>
                    <v-row align-content="center" class="mb-4" justify="space-around">
                        <v-switch v-model="isRemoteAuthentificated" color="success"
                                  dense hide-details label="Связь с сервером"
                                  readonly @click="checkConnectionToServer(true)"></v-switch>
                        <v-switch v-model="flightMission" color="success" dense hide-details
                                  label="Полетная миссия" readonly></v-switch>
                        <v-switch v-model="isODMAuthentificated" color="success" dense hide-details
                                  label="Видео сервер (ODM)"
                                  readonly @click="checkOdmConnection(true)"></v-switch>
                        <v-btn color="primary" x-small @click="showLogs = !showLogs">
                            Показать логи
                        </v-btn>
                    </v-row>
                    <v-row v-if="showLogs" align-content="center" class="logs-windows" justify="space-around">
                        <v-spacer></v-spacer>
                        <v-textarea v-model="getLogs" clearable outlined readonly
                                    @click:clear="clearLogs"></v-textarea>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row align-content="center" class="mb-4" justify="space-around">
                        <v-btn color="primary" small to="/specter">
                            Вернуться
                        </v-btn>
                        <v-btn color="primary" small @click="openStreamPage">
                            Показать изображение
                        </v-btn>
                        <v-btn color="primary" small
                               @click.stop="showParameters = !showParameters">
                            Параметры
                        </v-btn>
                        <v-btn :loading="loading" color="primary" small
                               @click="startRecording">Начать съемку
                        </v-btn>
                        <v-btn color="primary" small @click="rotateTripod(0)">
                            Влево
                        </v-btn>
                        <v-btn color="primary" small @click="rotateTripod(1)">
                            Вправо
                        </v-btn>
                    </v-row>

                    <flight-config v-if="showParameters"></flight-config>
                </v-flex>
            </div>
        </v-card>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <tree-view
            :isODMAuthentificated="isODMAuthentificated"
            :isRemoteAuthentificated="isRemoteAuthentificated">
        </tree-view>

        <authorization-dialog
            :dialog="remoteAuthDialog"
            title="Авторизация на удаленном сервере"
            @close="remoteAuthDialog = false"
            @login="remoteServerLogin">
        </authorization-dialog>
        <authorization-dialog
            :dialog="remoteODMAuthDialog"
            title="Авторизация на ODM сервере"
            @close="remoteODMAuthDialog = false"
            @login="remoteODMLogin">
        </authorization-dialog>
    </v-flex>
</template>

<script>
import FlightConfig from "@/components/support-view/FlightConfig";
import TreeView from "@/components/TreeView";
import {checkRemoteAuth, remoteAuth} from "@/api/UtilsAPI";
import {odmLogin, odmAuthStatus} from "@/api/AuthAPI";
import AuthorizationDialog from "@/components/support-view/AuthorizationDialog";

export default {
    name: "TestFormViewV2",

    components: {AuthorizationDialog, TreeView, FlightConfig},
    data() {
        return {
            showLogs: false,
            loading: false,
            serverLoading: false,
            timer: null,

            remoteAuthDialog: false,
            remoteODMAuthDialog: false,
            isRemoteAuthentificated: false,
            isODMAuthentificated: false,
            flightMission: false,
            connectionToVideoServer: true,

            time: '',
            showParameters: false,

            passwordVisible: false,
            username: '',
            password: ''
        }
    },
    created() {
        this.updateDateTime()
        this.checkConnectionToServer()
        this.checkOdmConnection()

        this.$store.dispatch('loadCameraConfig')
        this.$store.dispatch('updateServerTime', {'time': this.time})
        // this.$store.dispatch('authStatus')
        this.$store.dispatch('fetchVideoConfig')
        this.$store.dispatch('fetchMemoryStatus')
    },
    destroyed() {
        clearInterval(this.timer);
    },
    computed: {
        getLogs() {
            return this.$store.getters.getLogs
        },
        getShootingStatus() {
            return this.$store.getters.getShootingStatus
        },
        getMemoryStatus() {
            return this.$store.getters.getMemoryStatus
        },
        getUsagePercent() {
            return this.$store.getters.getUsagePercent
        },
    },
    methods: {
        checkConnectionToServer(openDialog) {
            checkRemoteAuth()
                .then(res => {
                    this.$toast.info('Вы авторизированы на удаленном сервере')
                    this.isRemoteAuthentificated = true
                    if (openDialog)
                        this.remoteAuthDialog = false
                })
                .catch(err => {
                    this.$toast.warning('Вы не авторизированы на удаленном сервере')
                    this.isRemoteAuthentificated = false
                    if (openDialog)
                        this.remoteAuthDialog = true
                })
        },
        checkOdmConnection(openDialog) {
            odmAuthStatus()
                .then(res => {
                    this.$toast.info('Вы авторизированы на ODM сервере')
                    this.isODMAuthentificated = true
                    if (openDialog)
                        this.remoteODMAuthDialog = false
                })
                .catch(err => {
                    this.$toast.warning('Вы не авторизированы на ODM сервере')
                    this.isODMAuthentificated = false
                    if (openDialog)
                        this.remoteODMAuthDialog = true
                })
        },
        rotateTripod(direction) {
            this.$store.dispatch('rotateTripod', {direction})
        },
        startRecording() {
            this.$store.dispatch('startRecording')
        },
        clearLogs() {
            this.$store.commit('clearLogs')
        },
        openStreamPage() {
            setTimeout(() => {
                window.open(`http://${location.hostname}:7070/stream?topic=/tiscam/image_hypecam`, '_blank');
            }, 5000)
            this.$store.dispatch('initImageShowing')
        },
        textColor: function () {
            if (this.getUsagePercent <= 60) {
                return 'lime'
            } else if (this.getUsagePercent >= 80) {
                return 'red'
            } else {
                return '#b3a104'
            }
        },
        updateDateTime() {
            const now = new Date()

            this.time = now.toLocaleString('en-US', {hour12: false})
            setTimeout(this.updateDateTime, 1000);
        },
        remoteServerLogin(payload) {
            remoteAuth(payload)
                .then(res => {
                    this.isRemoteAuthentificated = true
                    this.$toast.success('Вы успешно авторизировались на удаленном сервере')
                })
                .catch(err => {
                    this.isRemoteAuthentificated = false
                    this.$toast.error('Не удалось авторизироваться на удаленном сервере')
                })
        },
        remoteODMLogin(payload) {
            odmLogin(payload)
                .then(res => {
                    this.isODMAuthentificated = true
                    this.$toast.success('Вы успешно авторизировались на ODM сервере')
                })
                .catch(err => {
                    this.isODMAuthentificated = false
                    this.$toast.error('Не удалось авторизироваться на ODM сервере')
                })
        }
    }
}

// eslint-disable-next-line no-unused-vars
function getCurTime() {
    const curDate = new Date();
    return curDate.getDate().toString() + "/"
        + (curDate.getMonth() + 1).toString() + "/"
        + curDate.getFullYear().toString() + "@"
        + curDate.getHours().toString() + "-"
        + curDate.getMinutes().toString() + "-"
        + curDate.getSeconds().toString() + ".json";
}

</script>

<style scoped>

.v-input >>> .v-label {
    margin-bottom: 0 !important;
}

.up-arrow {
    transform: rotate(180deg);
    transition: all 1s;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

.logs-windows >>> .v-input__append-inner {
    margin-left: 12px;
}
</style>


