<template>
    <v-card color="secondary">
        <div class="d-flex justify-center">
            <v-card-title>Доступные наборы данных</v-card-title>
        </div>
        <v-row>
            <v-col cols="6">
                <v-treeview
                    v-if="getData.length !== 0"
                    :active.sync="active"
                    :items="getData"
                    :open.sync="open"
                    activatable
                    color="warning"
                    dense
                    item-key="name"
                    return-object
                    transition>
                    <template v-slot:prepend="{ item, active }">
                        <v-icon v-if="item.type">{{ childrenIcon(item) }}</v-icon>
                    </template>
                </v-treeview>
                <span v-else>
                    Нет доступных записей
                </span>
            </v-col>

            <v-col class="d-flex text-center justify-center" cols="6">
                <v-scroll-y-transition mode="out-in">
                    <div v-if="!chosenStamp"
                         class="title grey--text text--lighten-1 font-weight-light"
                         style="align-self: center;">
                        Выберите дату
                    </div>
                    <v-card v-else :key="chosenStamp.name" color="secondary" flat min-width="100%">
                        <v-card-text>
                            <h3 class="headline mb-2">
                                {{ chosenStamp.name }}
                            </h3>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-flex class="flex-row" tag="v-card-text">
                            <v-col v-show="chosenStamp.description !== ''" class="mb-2" tag="strong">
                                Описание:
                            </v-col>
                            <!--                                        <v-col >{{ chosenStamp.description }}</v-col>-->
                            <v-col>
                                <v-textarea v-show="chosenStamp.description !== ''"
                                            :value="chosenStamp.description" flat
                                            full-width readonly></v-textarea>
                            </v-col>

                            <v-col class="mb-2" tag="strong">
                                Объем:
                            </v-col>
                            <v-col>{{ chosenStamp.size }}</v-col>

                            <v-col v-show="chosenStamp.type === 'video'" class="mb-2" tag="strong">
                                Координаты:
                            </v-col>
                            <v-col v-show="chosenStamp.type === 'video'">{{ chosenStamp.center }}</v-col>
                        </v-flex>

                        <v-card-actions class="flex-row justify-center">
                            <v-btn :disabled="!isAbleToBeAssembled"
                                   :loading="assembleLoading"
                                   color="indigo darken-3" fab x-small @click="assembleSpecter">
                                <v-icon>mdi-cogs</v-icon>
                            </v-btn>
                            <v-btn :disabled="!isAbleToBeUploadedOnRemote"
                                   color="indigo darken-3" fab x-small @click="uploadOnServer">
                                <v-icon>mdi-upload</v-icon>
                            </v-btn>
                            <v-btn color="green" fab x-small @click="downloadConfig">
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                            <v-btn color="red" fab x-small @click="removeFromData">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-scroll-y-transition>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import {backendUrl} from "@/plugins/axios";

export default {
    name: "TreeView",
    props: {
        isODMAuthentificated: Boolean,
        isRemoteAuthentificated: Boolean
    },
    data() {
        return {
            assembleLoading: false,

            open: [],
            active: [],
        }
    },
    created() {
        this.$store.dispatch('fetchVideoConfig')
    },
    destroyed() {
        clearInterval(this.timer);
    },
    computed: {
        chosenStamp() {
            if (!this.active.length) return undefined
            const item = this.active[0]
            console.log(item);
            if (item.hasOwnProperty('type'))
                return item
            else
                return undefined
        },
        getData() {
            return this.$store.getters.getVideoData
        },
        isAbleToBeAssembled() {
            let item = this.chosenStamp
            if (!item || !item.hasOwnProperty('type'))
                return false
            if ((item.type === 'dir' || item.type === 'rec'))
                return true
            else
                return false
        },
        isAbleToBeUploadedOnRemote() {
            let item = this.chosenStamp
            if (!item || !item.hasOwnProperty('type'))
                return false
            if ((item.type === 'dir' || item.type === 'rpcam') && this.isODMAuthentificated && this.isRemoteAuthentificated)
                return true
            else
                return false
        },
    },
    methods: {
        childrenIcon(item) {
            if (item.type === 'dir' || item.type === 'video') {
                return 'mdi-folder-open'
            } else if (item.type === 'rpcam') {
                return 'mdi-airplane-takeoff'
            } else if (item.type === 'rec') {
                return 'mdi-cube-outline'
            } else {
                return 'mdi-camera-wireless-outline'
            }
        },
        removeFromData() {
            this.$store.dispatch('removeVideo', {id: this.chosenStamp.id, type: this.chosenStamp.type})
        },
        downloadConfig() {
            // Dangerous. May cause an errors due to hardcoded port
            const data = `${backendUrl}/flight/${this.chosenStamp.type}/${this.chosenStamp.id}`;
            const item = document.createElement('a');
            item.download = 'file.npy';
            item.href = data;

            document.body.appendChild(item);
            item.click();
            document.body.removeChild(item);
        },
        uploadOnServer() {
            this.serverLoading = true
            this.$toast.info(this.$t('notification/sendToUpload'))
            this.$store.dispatch('uploadOnServer', {
                id: this.chosenStamp.id,
                type: this.chosenStamp.type,
                endpoint: this.chosenStamp.type
            })
                .then(() => this.$toast.success(this.$t('notification/successUploadOnServer')))
                .catch(() => this.$toast.error(this.$t('notification/errorUploadOnServer')))
                .finally(() => this.serverLoading = false)
        },
        assembleSpecter() {
            this.assembleLoading = true
            this.$toast.info(this.$t('notification/sendToAssemble'))
            this.$store.dispatch('assembleSpecter', {id: this.chosenStamp.id, type: this.chosenStamp.type})
                .then(() => this.$toast.success(this.$t('notification/successAssemble')))
                .catch(() => this.$toast.error(this.$t('notification/errorAssemble')))
                .finally(() => this.assembleLoading = false)
        },
    }
}

function flatten(data) {
    return data.reduce(function (result, next) {
        result.push(next);
        if (next.children) {
            result = result.concat(flatten(next.children));
            next.children = [];
        }
        return result;
    }, []);
}
</script>

<style scoped>
.v-treeview >>> .v-treeview-node__level {
    width: 10px;
}

.v-treeview >>> .v-treeview-node__prepend {
    min-width: 0;
}

.v-treeview >>> .v-treeview-node__label {
    cursor: pointer;
}
</style>
